import React from 'react'
import netflixLogo from '../../Common/_assets/img/logo/netflix-logo-ribbon.png'

const Header = ({setShowSearch}) => {

    return (
      <header className="header__container">

        <div className="header__sticky d-flex">
          <div className="width_ctrl d-flex align-items-center">

            <div className=" header__logo-wrapper ms-0 ms-md-3 me-2 me-md-3">
              <a href="/"><img src={netflixLogo} className="header__logo" alt="Netflix" /></a>
            </div>

            <div className=" header__title d-flex flex-row justify-content-start">
              <a href="/">
                <span className="header__title--main">Series</span>
                <span className="header__title--tagline d-none d-md-block">For Your Consideration</span>
                <span className="header__title--tagline d-block d-md-none">FYC</span>
              </a>
            </div>

            <div className="d-flex header__buttons-container flex-row align-items-center justify-content-end ms-auto">
              <div className="d-flex header__buttons header__buttons--desktop justify-content-end ms-auto">



              </div>
            </div>

          </div>
        </div>
      </header>
    )
}

export default Header
